<template>
  <a-modal
    width="650px"
    class="manual-geographic-modal"
    :dialog-style="{ display: 'none' }"
    :footer="null"
    :closable="true"
    :visible="!!result"
    @cancel="onExit">
    <h3 class="flex align-center txt-32 txt-bold">
      {{ $t('components.titles.editGeographicalLocation') }}
    </h3>
    <p>
      {{ $t('components.description.enterNewNameForGeographicLocation', {
        location: result ? result.description : ''
      }) }}
    </p>
    <GeographicAreaNameInput
      v-if="!!result"
      v-model="name"
      :placeholder="$t('components.description.enterNewLocation')"/>
    <div class="flex justify-space-between w100">
      <a-button
        class="mt-24 w170"
        type="primary" ghost
        size="large"
        @click="onExit">
        {{ $t('values.cancel') }}
      </a-button>
      <a-button
        :disabled="!name || isSupervisor"
        class="mt-24 w170"
        type="primary"
        size="large"
        @click="save">
        {{ $t('values.save') }}
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex';
import GeographicAreaNameInput from '../geographic-area-name-input/geographic-area-name-input.vue';

export default {
  name: 'EditGeographicModal',
  components: {
    GeographicAreaNameInput
  },
  props: {
    result: {
      type: Object,
      required: false,
      default: undefined
    },
    onExit: {
      type: Function,
      required: true
    },
    onComplete: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      name: undefined
    };
  },
  computed: {
    ...mapState({
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    })
  },
  watch: {
    result() {
      this.name = undefined;
    }
  },
  methods: {
    save() {
      this.onComplete({ ...this.result, description: this.name });
      this.name = undefined;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
